import { colors } from "../styles/variables";

const WEBSITE_VARIANT_CONTROL = "master";
const WEBSITE_VARIANT = WEBSITE_VARIANT_CONTROL;

/* eslint-disable @typescript-eslint/no-unused-vars */
const IS_STAGING = process.env.REACT_APP_ENV_NAME === "staging";
const IS_LOCAL = process.env.REACT_APP_ENV_NAME === "local";
const IS_DEVELOP = process.env.REACT_APP_ENV_NAME === "develop";
const IS_PROD = process.env.REACT_APP_ENV_NAME === "production";
/* eslint-enable */

// Value can be found in Integrations > API Keys or .env files
export const REACT_APP_ITERABLE_API_KEY = process.env.REACT_APP_ITERABLE_API_KEY || "";
// Value can be found in Iterable's Settings > Apps and Websites > Website details > App Name
export const REACT_APP_ITERABLE_APP_NAME = process.env.REACT_APP_ITERABLE_APP_NAME || "";
const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

const WINE_SORTER = {
  COLOUR: "Colour",
  NEWEST: "Newest arrivals",
  WINE_BODY_ASC: "Body: Low to high",
  WINE_BODY_DESC: "Body: High to low",
  WINE_TYPE_ASC: "Grape Name: A to Z",
  WINE_TYPE_DESC: "Grape Name: Z to A",
  WINE_PRICE_POINT_ASC: "Price: low to high",
  WINE_PRICE_POINT_DESC: "Price: high to low",
  CREATED_DATE_ASC: "Date asc",
  CREATED_DATE_DESC: "Date desc",
  WINE_REGION_ASC: "Wine region ASC",
  WINE_REGION_DESC: "Wine region DESC",
  COUNTRY_ASC: "Country ASC",
  COUNTRY_DESC: "Country DESC",
  WINE_CLASS_ASC: "Wine ASC",
  WINE_CLASS_DESC: "Wine DESC",
  MEMBER_LIKELIHOOD_ASC: "Member likelihood ASC",
  MEMBER_LIKELIHOOD_DESC: "Member likelihood DESC",
};

export const MEMBER_RATING_SCORE_DESC = "MEMBER_RATING_SCORE_DESC";
export const CREATED_DATE_DESC = "CREATED_DATE_DESC";

const CELLAR_WINE_SORTER_ARR = [
  { id: CREATED_DATE_DESC, name: "Newest first" },
  { id: MEMBER_RATING_SCORE_DESC, name: "Highest rated first" },
];

const AU_STATES = new Map([
  ["ACT", "ACT"],
  ["NSW", "NSW"],
  ["NT", "NT"],
  ["QLD", "QLD"],
  ["SA", "SA"],
  ["TAS", "TAS"],
  ["VIC", "VIC"],
  ["WA", "WA"],
]);

const CONTACT_TYPE_ID_TO_ENUM = new Map([
  [1, "WINE_SELECTION"],
  [2, "DELIVERY"],
  [3, "BADGE"],
  [4, "SOCIAL"],
  [5, "NEWSLETTER"],
]);

const CONTACT_METHOD_ID_TO_ENUM = new Map([
  [1, "EMAIL"],
  [2, "SMS"],
]);

const SUBSCRIPTION_STATUS = {
  ACTIVE: "ACTIVE",
  SKIP: "SKIP",
  PAUSE: "PAUSE",
  EXPIRED: "EXPIRED",
  NEVER_ACTIVATED: "NEVER_ACTIVATED",
  CANCELLED: "CANCELLED",
};

const SUBSCRIPTION_STATUS_ID_TO_NAME = {
  1: "NEVER_ACTIVATED",
  2: "ACTIVE",
  3: "EXPIRED",
  4: "CANCELLED",
  5: "SKIP",
  6: "PAUSE",
};

export const DB_ID_SUBSCRIPTION_STATUS_NEVER_ACTIVATED = 1;
export const DB_ID_SUBSCRIPTION_STATUS_ACTIVE = 2;
export const DB_ID_SUBSCRIPTION_STATUS_SKIP = 5;

const SUBSCRIPTION_STATUS_NAME_TO_ID = {
  NEVER_ACTIVATED: 1,
  ACTIVE: 2,
  EXPIRED: 3,
  CANCELLED: 4,
  SKIP: 5,
  PAUSE: 6,
};

const DB_ID_SUBSCRIPTION_ACTIVE_REASON_OTHER = 28;

export const DB_ID_SUBSCRIPTION_WIDGET = 1;
export const DB_ID_PREVIOUS_BOX_WIDGET = 2;
export const DB_ID_REWARDS_PROGRESS_WIDGET = 3;
export const DB_ID_POINTS_PROGRESS_WIDGET = 4;
export const DB_ID_RATE_PAST_WINES_WIDGET = 5;
export const DB_ID_SAVED_LISTS_WIDGET = 6;
export const DB_ID_OUR_NEW_WINE_RELEASES_WIDGET = 7;
export const DB_ID_YOUR_PALATE_PROFILE_WIDGET = 8;
export const DB_ID_YOUR_RECIPES_WIDGET = 9;
export const DB_ID_CHALLENGES_IN_PROGRESS_WIDGET = 10;
export const DB_ID_WINE_101_COURSE_WIDGET = 11;
export const DB_ID_LATEST_WINE_STORIES_TIPS_WIDGET = 12;
export const DB_ID_INVITE_MATES_WIDGET = 13;
export const DB_ID_FACEBOOK_GROUP_WIDGET = 14;
export const DB_ID_USER_GUIDES_WIDGET = 15;

const SUBSCRIPTION_MONTHLY_BILLING_WAIT_DAYS = 4;
const SUBSCRIPTION_REFRESH_RECOMMENDATION_EXCLUSION_LIMIT = 5;

const MONTH_NAMES = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

const TOP_UP_VALUES = [6, 12];

const ALL_WINES_PAGINATION_LIMIT = 24;
const WINE_COLLECTIONS_PAGINATION_LIMIT = 5;
export const PRODUCT_COLLECTIONS_PAGINATION_LIMIT = 5;
export const PRODUCT_COLLECTION_PRODUCT_LIMIT = 20;
export const COLLECTION_SLUG_PAGINATION_LIMIT = 20;
const WINE_COLLECTIONS_SECTION_PAGINATION_LIMIT = 8;
export const WINE_RELATED_WINES_LIMIT = 5;
export const BADGE_CATEGORY_WIDGET_LIMIT = 20;
export const BADGE_CATEGORY_SIDE_TRAY_LIMIT = 50;
export const BADGE_CATEGORY_GRAPE_TYPE_OR_REGION_SIDE_TRAY_LIMIT = 100;

const REGISTER_INTEREST_DURATION_MONTHS = 3;

const MEMBER_PASSWORD_MINIMUM_CHARACTERS = 6;

const ADDRESS_UNAVAILABLE_IDS = new Map([
  [1, "If not home, deliver to local Post Office"],
  [2, "Authority to leave - under shelter in the shade"],
  [3, "Authority to leave - near the front door"],
  [4, "Authority to leave unattended"],
  [5, "Other (Specify)"],
  [6, "Signature required"],
  [7, "Leave at reception during work hours"],
  [8, "Authority to Leave - On porch/verandah/patio"],
]);

const AU_ADDRESS_UNAVAILABLE_IDS = new Map(ADDRESS_UNAVAILABLE_IDS);
const UK_ADDRESS_UNAVAILABLE_IDS = new Map(ADDRESS_UNAVAILABLE_IDS);
UK_ADDRESS_UNAVAILABLE_IDS.delete(1);

export const ADDRESS_UNAVAILABLE_OPTIONS = {
  POST_OFFICE: {
    id: 1,
    label: ADDRESS_UNAVAILABLE_IDS.get(1),
  },
  SHELTER: {
    id: 2,
    label: ADDRESS_UNAVAILABLE_IDS.get(2),
  },
  FRONT_DOOR: {
    id: 3,
    label: ADDRESS_UNAVAILABLE_IDS.get(3),
  },
  UNATTENDED: {
    id: 4,
    label: ADDRESS_UNAVAILABLE_IDS.get(4),
  },
  OTHER: {
    id: 5,
    label: ADDRESS_UNAVAILABLE_IDS.get(5),
  },
  SIGNATURE: {
    id: 6,
    label: ADDRESS_UNAVAILABLE_IDS.get(6),
  },
  RECEPTION: {
    id: 7,
    label: ADDRESS_UNAVAILABLE_IDS.get(7),
  },
  PORCH: {
    id: 8,
    label: ADDRESS_UNAVAILABLE_IDS.get(8),
  },
};

const PRODUCT_TYPE_IDS = {
  DB_ID_PRODUCT_TYPE_WINE: 1,
  DB_ID_PRODUCT_TYPE_GIFT: 2,
  DB_ID_PRODUCT_TYPE_SPECIAL_PACK: 3,
  DB_ID_PRODUCT_TYPE_SUBSCRIPTION_PRE_PAID: 4,
  DB_ID_PRODUCT_TYPE_SHIPPING_PRE_PAID: 5,
  DB_ID_PRODUCT_TYPE_SUBSCRIPTION: 6,
  DB_ID_PRODUCT_TYPE_FREEBIE: 7,
  DB_ID_PRODUCT_TYPE_BOX: 8,
  DB_ID_PRODUCT_TYPE_BOX_INSERT: 9,
  DB_ID_PRODUCT_TYPE_FLYER: 10,
};

const ORDER_ITEM_SOURCE_IDS = {
  DB_ID_ORDER_ITEM_SOURCE_SYSTEM_ADDON: 11,
};

const DB_ID_PRODUCT_SUBSCRIPTION = 1742;
const DB_ID_PRODUCT_GIFT = 243;

const WINE_CLASS_IDS = {
  DB_ID_WINE_CLASS_RED: 1,
  DB_ID_WINE_CLASS_WHITE: 2,
  DB_ID_WINE_CLASS_SPECIAL: 3,
  DB_ID_WINE_CLASS_ROSE: 4,
  DB_ID_WINE_CLASS_SPARKLING: 5,
  DB_ID_WINE_CLASS_SAKE: 6,
};

// TODO: Remove this once Orange Wine becomes a wine class, not only a wine type.
const WINE_TYPE_IDS = {
  DB_ID_WINE_TYPE_ORANGE_WINE: 106,
};

const WINE_PRICE_RANGE_IDS = {
  DB_ID_WINE_PRICE_RANGE_1: 1,
  DB_ID_WINE_PRICE_RANGE_2: 2,
  DB_ID_WINE_PRICE_RANGE_3: 3,
  DB_ID_WINE_PRICE_RANGE_4: 4,
  DB_ID_WINE_PRICE_RANGE_5: 5,
};

const WINE_PRICE_RANGE_ID_TO_MAX_PRICE = {
  [WINE_PRICE_RANGE_IDS.DB_ID_WINE_PRICE_RANGE_1]: 15,
  [WINE_PRICE_RANGE_IDS.DB_ID_WINE_PRICE_RANGE_2]: 25,
  [WINE_PRICE_RANGE_IDS.DB_ID_WINE_PRICE_RANGE_3]: 35,
  [WINE_PRICE_RANGE_IDS.DB_ID_WINE_PRICE_RANGE_4]: 50,
  [WINE_PRICE_RANGE_IDS.DB_ID_WINE_PRICE_RANGE_5]: 50,
};

const DISCOUNT_TYPE_IDS = {
  DB_ID_DISCOUNT_TYPE_PROMO_CODE: 1,
  DB_ID_DISCOUNT_TYPE_REFERRAL: 3,
  DB_ID_DISCOUNT_TYPE_GIVEAWAY: 4,
  DB_ID_DISCOUNT_TYPE_GIFT: 7,
  DB_ID_DISCOUNT_TYPE_FREE_BOX: 10,
};

const WINE_LIST_TYPE_IDS = {
  DB_ID_WINE_LIST_TYPE_MEMBER_RECOMMENDED: 1,
  DB_ID_WINE_LIST_TYPE_MEMBER_FAVOURITES: 2,
  DB_ID_WINE_LIST_TYPE_MEMBER_WISHLIST: 3,
  DB_ID_WINE_LIST_TYPE_MEMBER_CUSTOM: 4,
  DB_ID_WINE_LIST_TYPE_STAFF_CUSTOM: 5,
};

const WINE_LIST_NAME_MAX_LENGTH = 40;

const WINE_OAK_LEVEL = {
  NONE: "A_1",
  SOME: "A_2",
  LOTS: "A_3",
};

const DISCOUNT_TYPE_VALUES = {
  DB_ID_DISCOUNT_TYPE_REFERRAL: 15,
  DB_ID_DISCOUNT_TYPE_GIVEAWAY: 45,
  DB_ID_DISCOUNT_TYPE_FREE_BOX: 45,
};

const BADGE_CATEGORY_IDS = {
  DB_ID_BADGE_CATEGORY_SPECIAL: 1,
  DB_ID_BADGE_CATEGORY_WINE_PASSPORT: 2,
  DB_ID_BADGE_CATEGORY_WINE_TYPE_CHECKLIST: 3,
  DB_ID_BADGE_CATEGORY_WINE_REGION_CHECKLIST: 4,
  DB_ID_BADGE_CATEGORY_PRODUCTION_METHOD_CHECKLIST: 5,
  DB_ID_BADGE_CATEGORY_RANDOM_VARIABLE_REWARDS: 6,
  DB_ID_BADGE_CATEGORY_CHALLENGES: 7,
};

const BADGE_RULE_CONTENT_TYPE_MODELS = {
  WINE_TYPE: "winetype",
  WINE_CLASS: "wineclass",
  WINE_BODY: "winebody",
  WINE_PROD_METHOD: "wineproductionmethod",
  WINE_REGION: "wineregion",
  COUNTRY: "country",
  BADGE: "badge",
};

export const DB_ID_CHALLENGE_STATUS_NOT_STARTED = 1;
export const DB_ID_CHALLENGE_STATUS_IN_PROGRESS = 2;
export const DB_ID_CHALLENGE_STATUS_ACHIEVED = 3;
export const DB_ID_CHALLENGE_STATUS_EXPIRED = 4;
export const DB_ID_CHALLENGE_STATUS_QUIT = 5;

const DB_ID_COUNTRY_AUSTRALIA = 117;
const DB_ID_COUNTRY_UK = 191;

const SOCIAL_MEDIA_SITES = {
  FACEBOOK: "facebook",
  TWITTER: "twitter",
  FACEBOOK_MESSENGER: "facebook-messenger",
};

const FACEBOOK_TRACKING_CURRENCY = "AUD";

const DB_IDS_QUIZ_VERSION = {
  SIMPLE_QUIZ: 1,
  ADVANCED_QUIZ: 2,
};

const DB_IDS_QUIZ_ANSWER_WITH_WINE_QUANTITY = {
  SIMPLE_QUIZ: {
    ALL_REDS: 57,
    ALL_WHITES: 58,
    ALL_ROSES: 59,
    ALL_SPARKLINGS: 60,
    MIXED: 61,
  },
  ADVANCED_QUIZ: {
    ALL_REDS: 125,
    ALL_WHITES: 126,
    ALL_ROSES: 127,
    ALL_SPARKLINGS: 128,
    MIXED: 129,
  },
};

const DB_IDS_QUIZ_ANSWER_WITH_VERSION = {
  SIMPLE_QUIZ: {
    BEGINNER: 28,
    REGULAR: 29,
    ADVANCED: 30,
  },
  ADVANCED_QUIZ: {
    BEGINNER: 70,
    REGULAR: 71,
    ADVANCED: 72,
  },
};

const DB_IDS_QUIZ_QUESTION_WITH_VERSION = {
  SIMPLE_QUIZ: 11,
  ADVANCED_QUIZ: 23,
};

const DB_IDS_QUIZ_QUESTION_WITH_WINE_QUANTITY = {
  SIMPLE_QUIZ: 19,
  ADVANCED_QUIZ: 34,
};

const DB_IDS_QUIZ_QUESTION_WITH_PRICE_RANGE = {
  SIMPLE_QUIZ: 20,
  ADVANCED_QUIZ: 35,
};

export const QUIZ_QUESTION_WELCOME_PACK_SORT_ORDER = {
  SIMPLE_QUIZ: 12,
  ADVANCED_QUIZ: 15,
};

// Map value from one quiz to another, key is the quiz version before switch
export const BLACKLIST_QUESTION_SORT_ORDER_MAPPING = {
  SIMPLE_QUIZ: {
    [QUIZ_QUESTION_WELCOME_PACK_SORT_ORDER.SIMPLE_QUIZ]: QUIZ_QUESTION_WELCOME_PACK_SORT_ORDER.ADVANCED_QUIZ,
  },
  ADVANCED_QUIZ: {
    [QUIZ_QUESTION_WELCOME_PACK_SORT_ORDER.ADVANCED_QUIZ]: QUIZ_QUESTION_WELCOME_PACK_SORT_ORDER.SIMPLE_QUIZ,
  },
};

// Map value from one quiz to another, key is the quiz version before switch
export const BLACKLIST_QUESTION_ID_MAPPING = {
  SIMPLE_QUIZ: {
    [QUIZ_QUESTION_WELCOME_PACK_SORT_ORDER.SIMPLE_QUIZ]: QUIZ_QUESTION_WELCOME_PACK_SORT_ORDER.ADVANCED_QUIZ,
  },
  ADVANCED_QUIZ: {
    [QUIZ_QUESTION_WELCOME_PACK_SORT_ORDER.ADVANCED_QUIZ]: QUIZ_QUESTION_WELCOME_PACK_SORT_ORDER.SIMPLE_QUIZ,
  },
};

const DB_IDS_QUIZ_QUESTION_WITH_PRICE_RANGE_SORT_ORDER = 12;

const DB_IDS_QUIZ_ANSWERS_FOR_PRICE_RANGE = {
  ADVANCED_QUIZ: {
    [WINE_PRICE_RANGE_IDS.DB_ID_WINE_PRICE_RANGE_1]: 130,
    [WINE_PRICE_RANGE_IDS.DB_ID_WINE_PRICE_RANGE_2]: 131,
    [WINE_PRICE_RANGE_IDS.DB_ID_WINE_PRICE_RANGE_3]: 132,
    [WINE_PRICE_RANGE_IDS.DB_ID_WINE_PRICE_RANGE_4]: 133,
  },
};

const DB_ID_QUIZ_QUESTION_WITH_CHOOSE_3_FRUITS = {
  SIMPLE_QUIZ: 15,
  ADVANCED_QUIZ: 27,
};

const DB_ID_QUIZ_QUESTION_WITH_DEAL_BREAKERS = {
  ADVANCED_QUIZ: 33,
};

const DB_IDS_QUIZ_ANSWERS_FOR_DEAL_BREAKERS = {
  ADVANCED_QUIZ: {
    VEGAN: 121,
    NATURAL: 122,
    AUSTRALIAN_ONLY: 123,
    NONE_DEAL_BREAKERS: 124,
  },
};

const DB_ID_QUIZ_QUESTION_WITH_DEAL_BREAKERS_SORT_ORDER = 13;

const DB_ID_QUIZ_QUESTION_ADVENTUROUS = {
  SIMPLE_QUIZ: 22,
  ADVANCED_QUIZ: 37,
};

const DB_ID_GIFT_DELIVERY_SHIP_BOX = 1;
const DB_ID_GIFT_DELIVERY_SEND_EMAIL = 3;
const DB_ID_GIFT_DELIVERY_DIY = 4;

const DB_ID_ACTIVITY_TYPE_MONTHLY_RECOMMENDATIONS = 2;
const DB_ID_ACTIVITY_TYPE_ORDERED_SUBSCRIPTION = 3;
const DB_ID_ACTIVITY_TYPE_EARNED_BADGE = 9;
const DB_ID_ACTIVITY_TYPE_RECEIVED_CREDIT = 10;
const DB_ID_ACTIVITY_TYPE_EXTERNAL_ACTION = 11;
const DB_ID_ACTIVITY_TYPE_ADDED_AS_FRIEND = 13;
const DB_ID_ACTIVITY_TYPE_RECEIVED_COMMENT_ON_ACTIVITY = 14;
const DB_ID_ACTIVITY_TYPE_AUTO_RATE_BOTTLES = 15;
const DB_ID_ACTIVITY_TYPE_AUTO_FREE_SHIPPING = 16;
const DB_ID_ACTIVITY_TYPE_AUTO_CONNECT_TO_FRIENDS = 17;
const DB_ID_ACTIVITY_TYPE_AUTO_TASTES = 18;
const DB_ID_ACTIVITY_TYPE_AUTO_GIVE_A_BOX = 19;
const DB_ID_ACTIVITY_TYPE_AUTO_6_MONTHS = 20;
const DB_ID_ACTIVITY_TYPE_AUTO_FEEDBACK = 21;
const DB_ID_ACTIVITY_TYPE_AUTO_12_MONTHS = 22;
const DB_ID_ACTIVITY_TYPE_WINE_RUNNING_OUT_OF_STOCK = 27;

const DB_ID_ORDER_STATUS_AWAITING_AUTHORISATION = 6;

const DB_ID_ORDER_CATEGORY_SUBSCRIPTION_FIRST = 1;
const DB_ID_ORDER_CATEGORY_SUBSCRIPTION_ONGOING = 2;
const DB_ID_ORDER_CATEGORY_ONE_OFF_WINES = 3;
const DB_ID_ORDER_CATEGORY_GIFT_PURCHASE = 4;
const DB_ID_ORDER_CATEGORY_SPECIAL_PACK = 5;
const DB_ID_ORDER_CATEGORY_SUBSCRIPTION_PRE_PAID = 6;
const DB_ID_ORDER_CATEGORY_SHIPPING_PRE_PAID = 7;
const DB_ID_ORDER_CATEGORY_BULK_GIFTS = 8;

const DB_ID_SHIPPING_PRE_PAID_STATUS_ACTIVE = 2;

const DB_ID_WINE_CHARACTERISTIC_INFO_ACIDITY = 1;
const DB_ID_WINE_CHARACTERISTIC_INFO_SWEETNESS = 2;
const DB_ID_WINE_CHARACTERISTIC_INFO_TANNINS = 3;
const DB_ID_WINE_CHARACTERISTIC_INFO_BODY = 4;
const DB_ID_WINE_CHARACTERISTIC_INFO_FRUITINESS = 5;
const DB_ID_WINE_CHARACTERISTIC_INFO_OAK = 6;
const DB_ID_WINE_CHARACTERISTIC_INFO_ALCOHOL = 7;

const DB_ID_WINE_CLASS_RED = 1;
const DB_ID_WINE_CLASS_WHITE = 2;
const DB_ID_WINE_CLASS_DESSERT = 3;
const DB_ID_WINE_CLASS_ROSE = 4;
const DB_ID_WINE_CLASS_SPARKLING = 5;

const DB_IDS_CREDIT_TYPE = {
  DB_ID_CREDIT_TYPE_DISCOUNT_PERCENTAGE: 1,
  DB_ID_CREDIT_TYPE_DISCOUNT_VALUE: 2,
  DB_ID_CREDIT_TYPE_FREE_WINE: 3,
  DB_ID_CREDIT_TYPE_FREE_SHIPPING: 4,
  DB_ID_CREDIT_TYPE_INVITATION_TO_EVENT: 5,
  DB_ID_CREDIT_TYPE_ACCESS_TO_SPECIAL_BOTTLES: 6,
  DB_ID_CREDIT_TYPE_FREE_3_BOTTLE_MONTHLY_BOX: 7,
  DB_ID_CREDIT_TYPE_FREEBIE: 8,
};

const DB_IDS_WINE_BLACKLIST = {
  DB_ID_WINE_BLACKLIST_TYPE_NON_VEGAN: 4,
  DB_ID_WINE_BLACKLIST_TYPE_NON_NATURAL: 8,
};

const DB_IDS_OF_BLACKLISTED_PRICE_RANGES = {
  [DB_IDS_WINE_BLACKLIST.DB_ID_WINE_BLACKLIST_TYPE_NON_VEGAN]: [
    WINE_PRICE_RANGE_IDS.DB_ID_WINE_PRICE_RANGE_1,
  ],
  [DB_IDS_WINE_BLACKLIST.DB_ID_WINE_BLACKLIST_TYPE_NON_NATURAL]: [
    WINE_PRICE_RANGE_IDS.DB_ID_WINE_PRICE_RANGE_1,
    WINE_PRICE_RANGE_IDS.DB_ID_WINE_PRICE_RANGE_2,
  ],
};

const DB_ID_SPECIAL_PACK_TYPE = {
  ADVENT_CALENDAR: 2,
};

const FETCH_POLICY_NO_CACHE = "no-cache";
const FETCH_POLICY_NETWORK_ONLY = "network-only";
const FETCH_POLICY_CACHE_FIRST = "cache-first";
const FETCH_POLICY_CACHE_AND_NETWORK = "cache-and-network";
const FETCH_POLICY_CACHE_ONLY = "cache-only";

const ERROR_CODE_UNAUTHORIZED = "UNAUTHORIZED";
// TODO: Use the three constants below in loggings.js once DEV-2716 is implemented
const ERROR_MESSAGE_INVALID_GRANT = "invalid_grant";
const ERROR_MESSAGE_NOT_AUTHENTICATED = "User must be authenticated";
const ERROR_MESSAGE_ACCESS_TOKEN_UNAUTHORIZED = "Access token unauthorised.";
// TODO: Use the three constants above in loggings.js once DEV-2716 is implemented
const ERROR_MESSAGE_DATA_NOT_OWNED_BY_MEMBER = "User is not authorised to perform this.";
const ERROR_TYPE_ONUNHANDLEDREJECTION = "onunhandledrejection";
const ERROR_MESSAGE_PROMISE_REJECTION_EMPTY_VALUE = "Non-Error promise rejection captured with value:";
const ERROR_MESSAGE_PROMISE_REJECTION_NULL_VALUE = "Non-Error promise rejection captured with value: null";
const ERROR_MESSAGE_PROMISE_REJECTION_VALUE_WITH_ID =
  "Non-Error promise rejection captured with value: Object Not Found Matching Id:";
const PAYMENT_INTENT_STATUS_SUCCEEDED = "succeeded";
const PAYMENT_AUTHENTICATION_REQUIRED = "authentication_required";

const MEMBER_NAVBAR_ITEM_ID_TASTE_PROFILE = "tasteProfile";

const BILLING_DAY_CALENDAR_DAYS = 30;

const WINE_COLLECTIONS_WITH_COUNTRY_NAMES = {
  "West Australian Wonders": "West Australian wonders",
  "South American Treasures": "South American treasures",
  "South Aussie Superstars": "South Aussie superstars",
  "It Began in Africa": "It began in Africa",
};

const SETTINGS_TRAY = {
  PAUSE_OR_CANCEL: "pause-or-cancel",
  ACCOUNT_HOLDER: "account-holder",
  ADDRESSES: "addresses",
  BILLING_DAY: "billing-day",
  PAYMENT: "payment",
  DELIVERY_FREQUENCY: "delivery-frequency",
  GRAPES_TO_AVOID: "grapes-to-avoid",
  BOX_AND_SPEND: "box-and-spend",
  COMMUNICATIONS: "communications",
  BOX_INCLUSIONS: "box-inclusions",
};

export const CANCELLATION_CLOSE_TRAY = "close-cancellation-tray";
// TODO: Deprecate with BOX_CART_TOGGLE_V2
export const BOX_CART_TOGGLE_V2 = "box_cart_toggle_v2";

const DISPLAY_DATE_FORMAT = "DD/MM/YYYY";

const DEFAULT_VIDEO_ASPECT_RATIO = 1; /* 1 : 1 */

const REDEEM_GIFT_MEMBER_TYPE = {
  NEW: "new",
  MEMBER: "member",
};

const GUIDES_GHOST_API_VERSION = "v4";
const BLOG_GHOST_API_VERSION = "v4";

export const DASHBOARD_WIDGET_AMOUNT_ARTICLES_TO_FETCH = 3;
export const DASHBOARD_RECIPES_TO_FETCH = 5;
export const GHOST_FEATURED_FILTER = "featured:true";
export const GHOST_RECIPES_AND_PAIRINGS_FILTER = "tag:recipes-pairings";
export const GHOST_PRODUCTION_METHOD_IMAGE_SIZE = "w600";
export const GHOST_PRODUCTION_METHOD_CARD_TITLE = "Production Methods";
export const GHOST_IMAGE_SIZE = "w410h290";
export const RECIPE_TYPES = ["simple", "complex", "vegan", "vegetarian"];

export const YOUTUBE_WATCH_VIDEO_URL = "https://www.youtube.com/watch?v=";
export const YOUTUBE_PLAYLIST_ITEMS_API_URL = "https://youtube.googleapis.com/youtube/v3/playlistItems";
export const YOUTUBE_VIDEOS_API_URL = "https://www.googleapis.com/youtube/v3/videos";
export const YOUTUBE_UNDERSTANDING_WINE_LIST_ID = "PLv8rEnYuDIQMwqn36v-4Pu5UGEjpq2NFr";
export const YOUTUBE_UNDERSTANDING_WINE_LIST_PARAM = `&list=${YOUTUBE_UNDERSTANDING_WINE_LIST_ID}`;
export const REDIRECT_URL_PARAM = "redirect_url";

const GUIDES_TAB_BAR_SLUG = {
  WINE_101_GUIDES: "wine-101-guides",
  GUIDE_GRAPE_COLOR: "grape-type-guides",
  GUIDE_REGION: "region-guides",
  GUIDE_TASTING: "wine-tasting-guides",
  GUIDE_MORE: "food-and-pairing-guide",
};

const GUIDES_TITLE_NAME = {
  WINE_101_GUIDES: "Wine 101",
  GUIDE_GRAPE_COLOR: "Grape Varieties",
  GUIDE_REGION: "Wine Regions",
  GUIDE_TASTING: "Tasting",
  GUIDE_MORE: "Food and Wine",
};

const GUIDES_TAB_HEADER_COLOR = {
  WINE_101_GUIDES: colors.blueThin,
  GUIDE_GRAPE_COLOR: colors.purple,
  GUIDE_REGION: colors.successThin,
  GUIDE_TASTING: colors.pinkTint,
  GUIDE_MORE: colors.peachTint,
};

const GUIDES_CANONICAL_URL = "https://goodpairdays.com";

// These header replacements are used for guides articles that have a different <H1> header for better SEO.
// object structure: {'postSlug': 'articleTitle'}
const GUIDES_HEADER_REPLACEMENTS_FOR_SEO = {
  sweetness: "Sweetness in Wine",
  acidity: "Acidity in Wine",
  tannin: "Tannin in Wine",
  body: "Body in Wine",
  alcohol: "Alcohol in Wine",
  "fruit-flavours": "Fruit Flavours in Wine",
  oak: "Oak in Wine",
  earth: "Earth in Wine",
  minerality: "Minerality in Wine",
};

const HEADER_MENU_NAME_MAX_LENGTH = 20;

const WINE_LIST_SLUGS = {
  ALL_WINES: "all-wines",
  NEW_RELEASES: "recently-added",
};

const ORDER_CATEGORY_ID_TO_NAME = {
  1: "Subscription First",
  3: "One-off Wines",
  4: "Gift Purchase",
  5: "Special Pack",
};

const SHOULD_SHOW_CHRISTMAS_CUT_OFF_NOTICE = false;
export const CHRISTMAS_CUT_OFF_DATES_VIEW_STORAGE_KEY = "hasSeenChristmasCutOffDatesPopup";
export const CHRISTMAS_CUT_OFF_DATES_VIEW_STORAGE_KEY_TTL = 5259600000; // 2 months in ms

const AUTO_HIDE_SCROLL_BUFFER_IN_PX = 250;

const KEY_ENTER = "Enter";

const MIN_WINE_TITLE_CHARACTER_COUNT_TO_HYPHENATE = 32;
const MIN_WINE_TITLE_NAME_CHARACTER = 44;

const MAX_GIFT_NOTE_LENGTH = 575;

const LOADING_ALL_RECOMMENDATIONS_KEY = -2;
const LOADING_ADDITIONAL_BOTTLE_RECOMMENDATION_KEY = -1;
const LOADING_WINE_PAUSE = 700;
const DELETING_WINE_PAUSE = 200;

const ACCESS_TOKEN_EXPIRE_SECONDS = 60 * 60 * 24 * 10; // 10 days

const I18N_NAMESPACES = {
  ABOUT: "about",
  COMMON: "common",
  CONTACT: "contact",
  PRIVACY: "privacy",
  REVIEWS: "reviews",
  TOP_NAVBAR: "topNavbar",
  GIFTS: "gifts",
  GIFTS_REDEEM: "giftsRedeem",
  MY_DASHBOARD: "myDashboard",
  MY_ACCOUNT: "myAccount",
  MY_PROGRESS: "myProgress",
  MY_BADGES: "myBadges",
  MY_ORDERS: "myOrders",
  MY_CELLAR: "myCellar",
  QUIZ: "quiz",
  MY_BOX: "myBox",
  WINE_PAGES: "winePages",
  MY_TASTE_PROFILE: "myTasteProfile",
  CHECKOUT: "checkout",
  HOME: "home",
  FAQ: "faq",
  WELCOME_PACK: "welcomePack",
  SPECIAL_PACKS: "specialPacks",
  STRIPE: "stripe",
  REWARDS: "rewards",
  THANK_YOU: "thankYou",
  INVITE: "invite",
};

export const UNLEASH_INVITE_MATE_V2 = "referral-v2";
export const UNLEASH_WELCOME_PACK_SELECTION = "welcome_pack_selection";
export const UNLEASH_PRESELECTED_WELCOME_PACK = "preselected-welcome-pack";
export const UNLEASH_ITERABLE_MESSAGING = "iterable-messaging";
export const UNLEASH_EARN_POINTS_V3 = "earn-points-v3";
export const UNLEASH_TOPUP_V2 = "topup-v2";
export const UNLEASH_QUIZ_RESULT_FREQUENCY_SELECTION = "frequency-selection-on-quiz-results";
export const UNLEASH_NEW_PRODUCT_CATEGORIES = "new-product-categories";
export const UNLEASH_BEER_SUBSCRIPTION = "beer-subscription";

export const TOTAL_TASTE_CHALLENGE_ANSWERS = 6;

export const WINE_LIST_SIDE_PANELS = {
  WINE_LIST: "winelist",
  LIST_CREATION: "listCreation",
  DELETE_WINE: "deleteWines",
  DETAILS: "details",
  REVIEW: "review",
  TASTE_CHALLENGE: "tasteChallenge",
};

export const SIDE_PANELS = {
  MY_CELLAR_TASTE_CHALLENGE: "tasteChallenge",
  TOP_UP: "topUp",
  BUY_AGAIN: "buyAgain",
  WINE_CELLAR_DETAILS: "wineDetails",
  SAVE_WINE_TO_WINE_LIST: "saveWineToWineList",
  WINE_DETAILS: "wineDetails",
  CHALLENGE_BADGE: "challengeBadge",
  CHALLENGE_GAMEPLAY: "challengeGameplay",
  CHALLENGE_OPTIONS: "challengeOptions",
  BADGE_INFO: "badgeInfo",
  REWARD_INFO: "rewardInfo",
  LEVEL_INFO: "levelInfo",
  PAST_RECIPES: "pastRecipes",
  ONBOARDING: "onboarding",
  ADD_BOTTLE: "addBottle",
};

export const MOBILE_NAVBAR_ACCORDIONS = {
  USER: "user",
  WINES: "wines",
  PRODUCTS: "products",
  LEARN: "learn",
  ABOUT: "about",
  GIFTS: "gifts",
  REWARDS: "rewards",
};

const TASTEBUD_PROFILE_STATUS_FILTER = {
  ALL: "all",
  THIS_WINE: "wine",
  MEMBER_RATINGS: "ratings",
  QUIZ_PROFILE: "quiz",
};

export const ONBOARDING_GUIDES_ITEM_ID = 7;
export const ONBOARDING_APP_STORE_ITEM_ID = 8;
export const ONBOARDING_PLAY_STORE_ITEM_ID = 9;
export const ONBOARDING_POINTS_REWARDED_CHAPTER_1 = 15;
export const ONBOARDING_POINTS_REWARDED_CHAPTER_2 = 20;
export const NON_AUTO_MARK_COMPLETED_ITEMS: Array<number> = [13]; // Start a challenge

const TASTE_PROFILE_VIEW_STORAGE_KEY = "hasOpenedTasteProfile";
export const YOUTUBE_VIDEO_LIST_STORAGE_KEY = "youtubeVideoList";
export const YOUTUBE_VIDEO_DURATIONS_STORAGE_KEY = "youtubeVideoDurations";
export const SUBSCRIPTION_WIDGET_ANIMATION_STORAGE_EXPIRY_KEY = "subscriptionWidgetAnimationDisplayExpiry";

export const TASTE_PROFILE_FEEDBACK_SURVEY_URL = "https://thewinegallery.typeform.com/to/Pdsioils";
export const WINE_101_COURSE_URL = "https://thewinegallery.typeform.com/to/vHyTM6PQ";
export const TASTE_PROFILE_SURVEY_NAME_IN_ANALYTICS = "Taste Profile";
export const TASTE_PROFILE_WINE_TYPES_GUIDES_SLUG = "main-wine-styles-overview";
export const TASTE_PROFILE_PRODUCTION_METHODS_GUIDES_SLUG = "how-wine-is-made-overview";
export const TASTE_PROFILE_REGIONS_AND_COUNTRIES_GUIDES_SLUG = "main-wine-countries-and-regions";

const IDEAL_POSTCODE_STYLES_URL =
  "https://cdn.jsdelivr.net/npm/@ideal-postcodes/address-finder@1.2.1/css/address-finder.min.css";

const COUNTRIES = { UK: "uk", AU: "au" };

const COUNTRY_SPECIFIC_SETTINGS = {
  [COUNTRIES.AU]: {
    IS_COUNTRY_AU: true,
    MIN_AVG_PRICE_PER_BOTTLE: 15,
    MAX_AVG_PRICE_PER_BOTTLE: 50,
    GIFT_PRICE_OPTIONS: [60, 80, 100, 150, 200],
    SHIPPING_PRICE_PER_MONTH: 9,
    MIN_VOUCHER_PRICE: 54,
    MAX_VOUCHER_PRICE: 600,
    ADDRESS_UNAVAILABLE_IDS: AU_ADDRESS_UNAVAILABLE_IDS,
    DEFAULT_ADDRESS_UNAVAILABLE_OPTION: ADDRESS_UNAVAILABLE_OPTIONS.POST_OFFICE,
    APP_STORE_URL: "https://apps.apple.com/au/app/good-pair-days/id1465027299",
    PLAY_STORE_URL: "https://play.google.com/store/apps/details?id=com.goodpairdays.mobile",
    ADDRESS_FORM_URL: "/forms/Good Pair Days - AU Corporate Gifts Template.xlsx",
    FLAG: "🇦🇺",
    NAME: "Australia",
    SHORT_NAME: "AUS",
    CURRENCY_LABEL: "$AUD",
    SHIPPING_FEE: 9,
    DB_ID_BADGE_DOWNLOAD_OUR_APP: 1585,
  },
  [COUNTRIES.UK]: {
    IS_COUNTRY_UK: true,
    MIN_AVG_PRICE_PER_BOTTLE: 10,
    MAX_AVG_PRICE_PER_BOTTLE: 30,
    GIFT_PRICE_OPTIONS: [35, 50, 70, 90, 130, 170],
    SHIPPING_PRICE_PER_MONTH: 5,
    MIN_VOUCHER_PRICE: 35,
    MAX_VOUCHER_PRICE: 360,
    ADDRESS_UNAVAILABLE_IDS: UK_ADDRESS_UNAVAILABLE_IDS,
    DEFAULT_ADDRESS_UNAVAILABLE_OPTION: ADDRESS_UNAVAILABLE_OPTIONS.SHELTER,
    APP_STORE_URL: "https://apps.apple.com/gb/app/good-pair-days/id1465027299",
    PLAY_STORE_URL: "https://play.google.com/store/apps/details?id=com.goodpairdays.mobile",
    ADDRESS_FORM_URL: "/forms/Good Pair Days - UK Corporate Gifts Template.xlsx",
    FLAG: "🇬🇧",
    NAME: "United Kingdom",
    SHORT_NAME: "UK",
    CURRENCY_LABEL: "£GBP",
    SHIPPING_FEE: 5,
    DB_ID_BADGE_DOWNLOAD_OUR_APP: 9917,
  },
};
const USER_HAS_ACCEPTED_COOKIE_BOX = "userHasSeenCookieBox";
const OFF_SESSION = "off_session";
const POINTS_EARNED_ON_SIGN_UP = 10;

const WINE_FILTERS = {
  PRICE_RANGE: "winePriceRangeIds",
  COLOUR: "wineClassIds",
  COUNTRY: "countryIds",
  CUISINE: "cuisineIds",
  REGION: "wineRegionIds",
  DRY_SWEET: "wineSweetnessId",
  TANNIN: "wineTanninId",
  BODY: "wineBodyId",
  FRUITINESS: "wineFruitDrivenId",
  ACIDITY: "wineAcidityId",
  OAKY: "wineOakyId",
  OTHER: "wineProductionMethodIds",
  GRAPE_TYPE: "wineTypeIds",
  ALCOHOL_RANGE: "alcoholRange",
  COLLECTIONS: "wineListSlug",
  ORDER_BY: "orderBy",
};

const MIN_AVG_PRICE_PER_BOTTLE_FOR_DEAL_BREAKERS = {
  [COUNTRIES.AU]: {
    [DB_IDS_WINE_BLACKLIST.DB_ID_WINE_BLACKLIST_TYPE_NON_VEGAN]: 16,
    [DB_IDS_WINE_BLACKLIST.DB_ID_WINE_BLACKLIST_TYPE_NON_NATURAL]: 26,
  },
  [COUNTRIES.UK]: {
    [DB_IDS_WINE_BLACKLIST.DB_ID_WINE_BLACKLIST_TYPE_NON_VEGAN]: 10,
    [DB_IDS_WINE_BLACKLIST.DB_ID_WINE_BLACKLIST_TYPE_NON_NATURAL]: 15.5,
  },
};

export const NAVIGATION_MEMBER = "navigationMember";
export const NAVIGATION_WINES = "navigationWines";
export const NAVIGATION_LEARN = "navigationLearn";
export const NAVIGATION_GIFTS = "navigationGifts";
export const NAVIGATION_REWARDS = "navigationRewards";

export const WINE_FLAVOURS_ITEMS_PER_ROW = 4;
export const MIN_RATED_WINES_FOR_TASTE_PROFILE = 5;

export const REWARDS_WAYS_TO_EARN_POINTS_ID = "waysToEarnPoints";
export const REWARDS_WAYS_TO_EARN_POINTS_URL = `#${REWARDS_WAYS_TO_EARN_POINTS_ID}`;
export const REWARDS_BENEFITS_TABLE_ID = "benefitsTable";
export const REWARDS_BENEFITS_TABLE_URL = `#${REWARDS_BENEFITS_TABLE_ID}`;
export const REWARDS_FAQ_ID = "rewardsFaq";
export const REWARDS_FAQ_URL = `#${REWARDS_FAQ_ID}`;
export const REWARDS_LEVEL_MAP_ID = "rewardsLevelMap";
export const REWARDS_LEVEL_MAP_URL = `#${REWARDS_LEVEL_MAP_ID}`;
export const INVITE_MATES_FAQ_ID = "referralFaq";
export const INVITE_MATES_FAQ_URL = `#${INVITE_MATES_FAQ_ID}`;
export const TASTE_PROFILE_MATCH_MAKER_ID = "tasteProfileMatchMaker";
export const TASTE_PROFILE_WINE_TYPE_RANKING_ID = "tasteProfileWineTypeRanking";

export const INVITE_MATE_LIMIT = 5;

export const PRESELECTED_WELCOME_PACK_ID_COOKIE = "preselectedWelcomePackId";

export const BEER_BOX_ADD_ON = "Beer box";

export const BOX_AND_SPEND_COLORS = [colors.purpleThin, colors.yellow, colors.pinkTint, colors.blueThin];

export const SUBSCRIPTION_GIFT_MINIMUM_TOTAL_BOTTLES = 2;

export {
  IS_PROD,
  PAYMENT_AUTHENTICATION_REQUIRED,
  PAYMENT_INTENT_STATUS_SUCCEEDED,
  USER_HAS_ACCEPTED_COOKIE_BOX,
  OFF_SESSION,
  COUNTRIES,
  IDEAL_POSTCODE_STYLES_URL,
  WEBSITE_VARIANT,
  AU_STATES,
  BADGE_CATEGORY_IDS,
  HTTP_METHODS,
  CONTACT_TYPE_ID_TO_ENUM,
  CONTACT_METHOD_ID_TO_ENUM,
  DISCOUNT_TYPE_IDS,
  WINE_LIST_TYPE_IDS,
  WINE_OAK_LEVEL,
  DISCOUNT_TYPE_VALUES,
  SUBSCRIPTION_STATUS_ID_TO_NAME,
  SUBSCRIPTION_STATUS_NAME_TO_ID,
  WINE_SORTER,
  CELLAR_WINE_SORTER_ARR,
  SUBSCRIPTION_STATUS,
  MONTH_NAMES,
  REGISTER_INTEREST_DURATION_MONTHS,
  DB_ID_COUNTRY_AUSTRALIA,
  DB_ID_COUNTRY_UK,
  PRODUCT_TYPE_IDS,
  WINE_CLASS_IDS,
  WINE_TYPE_IDS,
  WINE_PRICE_RANGE_IDS,
  WINE_PRICE_RANGE_ID_TO_MAX_PRICE,
  FETCH_POLICY_CACHE_AND_NETWORK,
  FETCH_POLICY_NETWORK_ONLY,
  FETCH_POLICY_NO_CACHE,
  FETCH_POLICY_CACHE_FIRST,
  FETCH_POLICY_CACHE_ONLY,
  SOCIAL_MEDIA_SITES,
  FACEBOOK_TRACKING_CURRENCY,
  DB_IDS_QUIZ_VERSION,
  DB_IDS_QUIZ_ANSWER_WITH_VERSION,
  DB_IDS_QUIZ_QUESTION_WITH_VERSION,
  DB_IDS_QUIZ_QUESTION_WITH_WINE_QUANTITY,
  DB_IDS_QUIZ_ANSWER_WITH_WINE_QUANTITY,
  DB_IDS_QUIZ_QUESTION_WITH_PRICE_RANGE,
  DB_IDS_QUIZ_ANSWERS_FOR_PRICE_RANGE,
  DB_IDS_QUIZ_QUESTION_WITH_PRICE_RANGE_SORT_ORDER,
  DB_ID_QUIZ_QUESTION_WITH_CHOOSE_3_FRUITS,
  DB_ID_QUIZ_QUESTION_WITH_DEAL_BREAKERS,
  DB_IDS_QUIZ_ANSWERS_FOR_DEAL_BREAKERS,
  DB_ID_QUIZ_QUESTION_WITH_DEAL_BREAKERS_SORT_ORDER,
  DB_ID_QUIZ_QUESTION_ADVENTUROUS,
  DB_ID_GIFT_DELIVERY_SHIP_BOX,
  DB_ID_GIFT_DELIVERY_SEND_EMAIL,
  DB_ID_GIFT_DELIVERY_DIY,
  DB_ID_ACTIVITY_TYPE_MONTHLY_RECOMMENDATIONS,
  DB_ID_ACTIVITY_TYPE_ORDERED_SUBSCRIPTION,
  DB_ID_ACTIVITY_TYPE_EARNED_BADGE,
  DB_ID_ACTIVITY_TYPE_RECEIVED_CREDIT,
  DB_ID_ACTIVITY_TYPE_EXTERNAL_ACTION,
  DB_ID_ACTIVITY_TYPE_ADDED_AS_FRIEND,
  DB_ID_ACTIVITY_TYPE_RECEIVED_COMMENT_ON_ACTIVITY,
  DB_ID_ACTIVITY_TYPE_AUTO_RATE_BOTTLES,
  DB_ID_ACTIVITY_TYPE_AUTO_FREE_SHIPPING,
  DB_ID_ACTIVITY_TYPE_AUTO_CONNECT_TO_FRIENDS,
  DB_ID_ACTIVITY_TYPE_AUTO_TASTES,
  DB_ID_ACTIVITY_TYPE_AUTO_GIVE_A_BOX,
  DB_ID_ACTIVITY_TYPE_AUTO_6_MONTHS,
  DB_ID_ACTIVITY_TYPE_AUTO_FEEDBACK,
  DB_ID_ACTIVITY_TYPE_AUTO_12_MONTHS,
  DB_ID_ACTIVITY_TYPE_WINE_RUNNING_OUT_OF_STOCK,
  DB_ID_ORDER_STATUS_AWAITING_AUTHORISATION,
  DB_ID_ORDER_CATEGORY_SUBSCRIPTION_FIRST,
  DB_ID_ORDER_CATEGORY_SUBSCRIPTION_ONGOING,
  DB_ID_ORDER_CATEGORY_ONE_OFF_WINES,
  DB_ID_ORDER_CATEGORY_GIFT_PURCHASE,
  DB_ID_ORDER_CATEGORY_SPECIAL_PACK,
  DB_ID_ORDER_CATEGORY_SUBSCRIPTION_PRE_PAID,
  DB_ID_ORDER_CATEGORY_SHIPPING_PRE_PAID,
  DB_ID_ORDER_CATEGORY_BULK_GIFTS,
  DB_ID_SHIPPING_PRE_PAID_STATUS_ACTIVE,
  DB_ID_PRODUCT_SUBSCRIPTION,
  DB_ID_PRODUCT_GIFT,
  TOP_UP_VALUES,
  ERROR_MESSAGE_INVALID_GRANT,
  ERROR_CODE_UNAUTHORIZED,
  ERROR_MESSAGE_NOT_AUTHENTICATED,
  ERROR_MESSAGE_ACCESS_TOKEN_UNAUTHORIZED,
  ERROR_MESSAGE_DATA_NOT_OWNED_BY_MEMBER,
  ERROR_TYPE_ONUNHANDLEDREJECTION,
  ERROR_MESSAGE_PROMISE_REJECTION_EMPTY_VALUE,
  ERROR_MESSAGE_PROMISE_REJECTION_NULL_VALUE,
  ERROR_MESSAGE_PROMISE_REJECTION_VALUE_WITH_ID,
  ALL_WINES_PAGINATION_LIMIT,
  WINE_COLLECTIONS_PAGINATION_LIMIT,
  SUBSCRIPTION_MONTHLY_BILLING_WAIT_DAYS,
  SUBSCRIPTION_REFRESH_RECOMMENDATION_EXCLUSION_LIMIT,
  DB_ID_SUBSCRIPTION_ACTIVE_REASON_OTHER,
  BILLING_DAY_CALENDAR_DAYS,
  WINE_COLLECTIONS_WITH_COUNTRY_NAMES,
  SETTINGS_TRAY,
  DISPLAY_DATE_FORMAT,
  DEFAULT_VIDEO_ASPECT_RATIO,
  REDEEM_GIFT_MEMBER_TYPE,
  WINE_COLLECTIONS_SECTION_PAGINATION_LIMIT,
  GUIDES_GHOST_API_VERSION,
  BLOG_GHOST_API_VERSION,
  GUIDES_TAB_BAR_SLUG,
  GUIDES_TITLE_NAME,
  GUIDES_TAB_HEADER_COLOR,
  GUIDES_CANONICAL_URL,
  HEADER_MENU_NAME_MAX_LENGTH,
  WINE_LIST_SLUGS,
  ORDER_CATEGORY_ID_TO_NAME,
  GUIDES_HEADER_REPLACEMENTS_FOR_SEO,
  SHOULD_SHOW_CHRISTMAS_CUT_OFF_NOTICE,
  AUTO_HIDE_SCROLL_BUFFER_IN_PX,
  KEY_ENTER,
  MIN_WINE_TITLE_CHARACTER_COUNT_TO_HYPHENATE,
  MIN_WINE_TITLE_NAME_CHARACTER,
  MAX_GIFT_NOTE_LENGTH,
  LOADING_ALL_RECOMMENDATIONS_KEY,
  LOADING_ADDITIONAL_BOTTLE_RECOMMENDATION_KEY,
  LOADING_WINE_PAUSE,
  DELETING_WINE_PAUSE,
  ACCESS_TOKEN_EXPIRE_SECONDS,
  I18N_NAMESPACES,
  TASTEBUD_PROFILE_STATUS_FILTER,
  DB_ID_WINE_CHARACTERISTIC_INFO_ACIDITY,
  DB_ID_WINE_CHARACTERISTIC_INFO_SWEETNESS,
  DB_ID_WINE_CHARACTERISTIC_INFO_TANNINS,
  DB_ID_WINE_CHARACTERISTIC_INFO_BODY,
  DB_ID_WINE_CHARACTERISTIC_INFO_FRUITINESS,
  DB_ID_WINE_CHARACTERISTIC_INFO_OAK,
  DB_ID_WINE_CHARACTERISTIC_INFO_ALCOHOL,
  DB_ID_WINE_CLASS_RED,
  DB_ID_WINE_CLASS_WHITE,
  DB_ID_WINE_CLASS_DESSERT,
  DB_ID_WINE_CLASS_ROSE,
  DB_ID_WINE_CLASS_SPARKLING,
  DB_ID_SPECIAL_PACK_TYPE,
  MEMBER_NAVBAR_ITEM_ID_TASTE_PROFILE,
  TASTE_PROFILE_VIEW_STORAGE_KEY,
  COUNTRY_SPECIFIC_SETTINGS,
  POINTS_EARNED_ON_SIGN_UP,
  WINE_FILTERS,
  BADGE_RULE_CONTENT_TYPE_MODELS,
  WINE_LIST_NAME_MAX_LENGTH,
  DB_IDS_CREDIT_TYPE,
  DB_IDS_WINE_BLACKLIST,
  DB_IDS_OF_BLACKLISTED_PRICE_RANGES,
  MIN_AVG_PRICE_PER_BOTTLE_FOR_DEAL_BREAKERS,
  MEMBER_PASSWORD_MINIMUM_CHARACTERS,
  ORDER_ITEM_SOURCE_IDS,
};
