import React from "react";
import styled from "styled-components";

import externalUrls from "../../../helpers/externalUrls";

import { ReactComponent as youtube } from "../../../assets/images/icons/youtube.svg";
import { ReactComponent as facebook } from "../../../assets/images/icons/facebook.svg";
import { ReactComponent as instagram } from "../../../assets/images/icons/instagram.svg";

import { colors, transition } from "../../../styles/variables";

const socialListData = [
  {
    id: "youtube",
    title: "youtube",
    href: externalUrls.YOU_TUBE,
    svgSrc: youtube,
  },
  {
    id: "facebook",
    title: "facebook",
    href: externalUrls.FACEBOOK,
    svgSrc: facebook,
  },
  {
    id: "instagram",
    title: "instagram",
    href: externalUrls.INSTAGRAM,
    svgSrc: instagram,
  },
];

const StyledSocialList = styled.ul`
  list-style: none;
`;

const StyledSocialListItem = styled.li`
  display: inline-block;
  &:not(:first-child) {
    margin-left: 10px;
  }
`;

const StyledSocialListLink = styled.a`
  display: inline-block;
`;

const StyledSocialListIcon = styled.div`
  width: 100%;
  max-width: 30px;

  svg path {
    fill: ${colors.youtubeDark}; /* Set your default color here */
    transition: ${transition.baseTransition};
  }

  &:hover svg path {
    fill: ${colors.white};
  }
`;

const SocialList = () =>
  socialListData.length > 0 ? (
    <StyledSocialList>
      {socialListData.map((socialListItem) => (
        <StyledSocialListItem key={socialListItem.id}>
          <StyledSocialListLink target="_blank" href={socialListItem.href} rel="noopener">
            <StyledSocialListIcon>
              <socialListItem.svgSrc alt={socialListItem.title} />
            </StyledSocialListIcon>
          </StyledSocialListLink>
        </StyledSocialListItem>
      ))}
    </StyledSocialList>
  ) : null;

export default SocialList;
